import React from 'react';

import BlockContent from 'molecules/BlockContent';
import Container from 'molecules/Container';
import SEO from 'molecules/SEO';

import { pageMetaFields } from 'lib/fragments';
import Image from 'molecules/Image';
import WithBGImage from 'molecules/WithBGImage';

import './index.scss';


/**
 * Default template.
 */
export const query = graphql`
 query ( $id: String ) {
   page: sanityPage( id: { eq: $id } ){
     id
     slug {
       current
     }
     title
     _rawPageTemplate(resolveReferences: { maxDepth: 10 })
     ...pageMetaFields
   }
 }
`;
export default function Lettersemplate( { data, path } ) {

  const { page } = data;
  const {
    meta,
    title,
    _rawPageTemplate: {
      Letters: {
        content,
        hero
      }
    }
  } = page;

  console.log( { hero, content } );

  return(

    <div className="Letters">

      <SEO
      title={ title }
      path={ path }
      { ...meta }
      />

      { !! hero &&

        <header>

          <Image
          className="Letters__img"
          data={ !! hero.image ? hero.image.asset : null }
          />

          <Container
          className="Letters__top"
          size="lg"
          >
            <Container size="sm">
              <div className="Letters__top-prehding h-serif-i-md">
                { hero.preheading }
              </div>
              <h1 className="Letters__top-hding h-xl">
                { hero.heading }
              </h1>
              <p className="Letters__top-summary p-xl">
                { hero.blurb }
              </p>


            </Container>

          </Container>

        </header>

      }

      { !! content &&
        <div className="Letters__content container-md p-md mt-8">
          <BlockContent
          blocks={ content }
          />
        </div>
     
      }

    
    </div>

  )


}